@font-face {
    font-family: "Lobster";
    src: url("https://cdn.mde-content.com/fonts/Lobster-Regular.ttf") format("truetype") ;
}

@font-face {
    font-family: "Lobster";
    src: url("https://cdn.mde-content.com/fonts/Lobster-Regular.ttf") format("truetype")  ;
    font-weight: bold;
}

.kids {

    .header__logo img {
        filter: inherit !important;
    }

    .listing-filters,
    .listing-filters a,
    .block-head__content h1,
    .block-head__title h1,
    .block-head__title a,
    .block-head__content h3,
    .mde__region_block__content h1 {
        font-family: "Lobster", "Source Sans Pro", Arial, Helvetica, sans-serif;
        letter-spacing: 0.3rem;
        text-transform: inherit;
        color: #13235B !important;
    }

    .block-head__content h3,
    .listing-filters a {
        font-size: 2.4rem;
        text-transform: uppercase;
    }

    .mde__region_block__content_collection {
        .summary-card__content {
            background-color: #0087d8;
            background-image: url(https://cdn.mde-content.com/website/kids/plane.png);
            background-position: left -60px bottom -20px;
            background-size: 150px;
            background-repeat: no-repeat;

            * {
                color: white;
            }
        }
    }
}

.kids__internal {

    .main {
        background-color: #fff
    }

    .block-head {
        background-image: url(https://res.cloudinary.com/deunyl3k1/image/upload/s--2a_62fOf--/c_thumb,f_auto,h_1080,q_auto,w_1920/v1/prio/default/0001/34/39609fa8025ca930b69811f39a4eb4220f1a6c36.jpeg);
        //h1, h3, a {
        //    color: white !important;
        //}
    }


}

